<template>
<div>
  <form 
    method="post" 
    class="form" 
    ref="mainForm"
    @submit.prevent="submit"
  >
    <p class="title title--3">
      <span v-if="innerProduct.id">Ajouter un produit</span>
      <span v-else>Editer le produit</span>
    </p>

    <fieldset class="form__field">
      <input type="checkbox" id="active" class="form__checkbox" v-model="innerProduct.active" />
      <label class="form__label" for="active">Produit actif</label>
    </fieldset>

    <fieldset class="form__field">
      <label class="form__label" for="name">Produit</label>
      <input type="text" id="name" placeholder="Nom du produit" class="form__input" v-model="innerProduct.name" />
    </fieldset>

    <fieldset class="form__field">
      <label class="form__label" for="name">Etiquette</label>
      <input type="text" id="name" placeholder="Nom du produit" class="form__input" v-model="innerProduct.label" />
    </fieldset>

    <fieldset class="form__field">
      <label class="form__label">Catégorie</label>

      <ul class="form__button-container">
        <li v-for="(category, idCategory) in innerProduct.categories" :key="idCategory">
          {{ category }}

          <button 
            class="btn btn--danger btn--small"
            type="button"
            @click="removeElement('categories', idCategory)"
          >
            <i class="fa fa-trash"></i>
          </button>
        </li>
      </ul>

      <DataSelect
        label="Ajouter une catégorie"
        :value="innerProduct.categories"
        :options="availableCategories"
        @add="addElement('categories', $event)"
        @remove="removeElement('categories', $event)"
      />
    </fieldset>



    <fieldset class="form__field">
      <label class="form__label">Tags (sous-catégorie)</label>

      <ul class="form__button-container">
        <li v-for="(tag, idTag) in innerProduct.tags" :key="idTag">
          {{ tag }}

          <button 
            class="btn btn--danger btn--small"
            type="button"
            @click="removeElement('tags', idTag)"
          >
            <i class="fa fa-trash"></i>
          </button>
        </li>
      </ul>

      <DataSelect
        label="Ajouter une sous-catégorie"
        :value="innerProduct.tags"
        :options="availableTags"
        @add="addElement('tags', $event)"
        @remove="removeElement('tags', $event)"
      />
    </fieldset>

    <fieldset class="form__field">
      <label for="free-text" class="form__label">
        Champ libre
      </label>
      <vue-editor 
        id="editor--free-text"
        v-model="innerProduct.text"
        :editor-toolbar="customToolbar"
      />
    </fieldset>

    <fieldset class="form__field">
      <label class="form__label">Prix</label>
      <div 
        v-for="(price, idPrice) in innerProduct.prices"
        :key="idPrice"
        class="form__button-container"
      >
        <input 
          v-model="price.price"
          placeholder="prix"
          type="text"
          class="form__input form__input--split"
        />

        <input 
          v-model="price.unitPrice"
          placeholder="unité de prix (Kg, pièce, ...)"
          type="text"
          class="form__input form__input--split"
        />

        <button class="btn btn--danger" type="button" @click="removeElement('prices', idPrice)">
          <i class="fa fa-trash"></i>
        </button>
      </div>
        <button class="btn" type="button" @click="addElement('prices', {price: '', unitPrice: ''})">
          <i class="fa fa-plus"></i> Ajouter un prix
        </button>
    </fieldset>

    <fieldset class="form__field">
      <label class="form__label">Origine</label>
      <div 
        v-for="(origin, idOrigin) in innerProduct.origin"
        :key="idOrigin"
      >
        <input 
          v-model="origin.country"
          placeholder="Pays d'origine"
          type="text"
          class="form__input form__input--split"
        />
        <input 
          v-model="origin.region"
          placeholder="(Région)"
          type="text"
          class="form__input form__input--split"
        />
        <button class="btn btn--danger" type="button" @click="removeElement('origin', idOrigin)">
          <i class="fa fa-trash"></i>
        </button>


        <div 
          v-for="(place, idPlace) in origin.places"
          :key="idPlace"
          class="form__button-container"
        >
          <input 
            v-model="origin.places[idPlace].place"
            placeholder="Lieu d'origine (Ferme, Lycée, Boulangerie, ...)"
            type="text"
            class="form__input form__input--split"
          />

          <input 
            v-model="origin.places[idPlace].region"
            placeholder="(Région)"
            type="text"
            class="form__input form__input--split"
          />

          <button class="btn btn--danger" type="button" @click="removeSubElement('origin', 'places', idOrigin, idPlace)">
            <i class="fa fa-trash"></i>
          </button>
        </div>

        <button class="btn" type="button"  @click="addSubElement('origin', 'places', idOrigin, {place: '', region: ''})">
          <i class="fa fa-plus"></i> Ajouter un lieu d'origine en {{ origin.country}}
        </button>
      </div>

      <button class="btn" type="button"  @click="addElement('origin', {country: '', region: ''})">
        <i class="fa fa-plus"></i> Ajouter un pays d'origine
      </button>
    </fieldset>

    <fieldset class="form__field">
      <label for="ingredients" class="form__label">
        Ingrédients
      </label>
      <vue-editor 
        id="editor--ingredients"
        v-model="innerProduct.ingredients"
        :editor-toolbar="customToolbar"
      />
    </fieldset>

    
    
    <button class="btn btn--primary">
      Sauvegarder
    </button>
  </form>

  <form
    v-if="innerProduct.id"
    method="post"
    class="form"
    enctype="multipart/form-data"
    @submit.prevent="uploadPhoto"
  >
    <p class="title title--3">
      <span>Ajouter une photo</span>
    </p>

    <fieldset class="form__field">
      <label>Photo :</label>
      <input type="file" name="file" @change="uploadFile" />
    </fieldset>

    <div>
      <button class="btn btn--primary">
        Ajouter la photo
      </button>
    </div>
</form>
</div>
</template>

<script>
import axios from 'axios';
import { VueEditor } from 'vue2-editor';
import { mapGetters } from 'vuex';
import { DataSelect } from '../Common';

export default {
  components: { DataSelect, VueEditor },

  props: {
    product: {
      type: Object,
      default: () => {},
    },

    availableCategories: {
      type: Array,
      default: () => [],
    },

    availableTags: {
      type: Array,
      default: () => [],
    }
  },

  data() {
    return {
      innerProduct: {},
      files: [],
      customToolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
      ]
    };
  },

  computed: {
    ...mapGetters('app', ['baseurl']),
  },

  watch: {
    product: {
      immediate: true,
      nested: true,
      handler() {
        this.innerProduct = Object.assign({}, this.product);
      }
    }
  },

  methods: {
    submit(e) {
      e.preventDefault();

      if (this.innerProduct.id) return this.$emit('save', this.innerProduct);

      this.$emit('create', this.innerProduct);
    },

    addElement(key, elem = 'ingrédient') {
      if (!this.innerProduct[key]) {
        this.$set(this.innerProduct, key, []);
      }

      this.$nextTick(() => {
        this.innerProduct[key].push(elem);
      });
    },

    removeElement(key, index) {
      this.innerProduct[key].splice(index, 1);
    },

    addSubElement(parentKey, key, index, elem = '') {
      if (!this.innerProduct[parentKey][index][key]) {
        this.$set(this.innerProduct[parentKey][index], key, []);
      }

      this.innerProduct[parentKey][index][key].push(elem);
    },

    removeSubElement(parentKey, key, index, elIndex) {
      this.innerProduct[parentKey][index][key].splice(elIndex, 1);
    },

    uploadFile(e) {
      this.files.push(...e.target.files);
    },

    async uploadPhoto() {
      if (this.files.length > 0) {
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        }

        try {
          let fileUpload = new FormData();
          fileUpload.append('name', 'file');
          fileUpload.append('file', this.files[0]);
          const response = await axios.post(`${this.baseurl}/products/upload`, fileUpload, config);

          this.$emit('saveFile', {
            id: this.innerProduct.id,
            path: response.data.file,
          });
        } catch (error) {
          this.$emit('error', error);
        } 
      }
    },
  },
};
</script>