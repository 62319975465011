<template>
  <div class="page-wrapper">
    
    <p class="title title--1">Produits</p>

    <button class="btn" @click="addProduct">
      Ajouter un produit
    </button>

    <button class="btn" @click="saveOrder">
      Enregistrer le tri
    </button>

    <draggable tag="table" v-model="productList" class="products__container table">
      <tr class="product" v-for="(product, i) in productList" :key="i">
        <td class="drag">
          <i class="fa fa-arrows-alt-v"></i>
        </td>
        <td>
          <button @click="toggleActive(i)" class="btn--no-tag">
            <span class="valid" v-if="product.active">Activé <i class="fa fa-check"></i> </span>
            <span class="danger" v-else>Désactivé <i class="fa fa-times"></i> </span>
          </button>
        </td>

        <td>
          <img :src="`${baseurl}/${product.picture}`" style="width: 30px;" alt="">
        </td>
        <td>
          <p class="title">{{ product.name }}</p>
        </td>

        <td>
          <p v-for="(price, idPrice) in product.prices" :key="idPrice">
            {{ price.price }}€ ({{ price.unitPrice }})
          </p>
        </td>

        <td>
          <ul>
            <li v-for="(category, idCategory) in product.categories" :key="idCategory">
              <p>{{ category }}</p>
            </li>
          </ul>
        </td>

        <td>
          <ul v-if="product.tags">
            <li v-for="(tag, idTag) in product.tags" :key="idTag">
              <p>{{ tag }}</p>
            </li>
          </ul>
        </td>

        <td>
          <button class="btn" @click="edit(i)">
            <i class="fa fa-pencil"></i> Editer
          </button>

          <button class="btn btn--danger" @click="remove(i)">
            <i class="fa fa-trash"></i> Supprimer
          </button>
        </td>
      </tr>
    </draggable>
    
    <Toaster />
    <Modal v-if="showModal" @close="close">
      <div slot="body">
        <FormProduct 
          :product="activeProduct"
          :availableCategories="availableCategories"
          :availableTags="availableTags"
          @create="createProduct"
          @save="saveProduct"
          @error="error"
          @saveFile="saveFile"
        />
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import draggable from 'vuedraggable';
import Products from '@/firebase/products-db';
import { Toaster, Modal } from '@/components/Utils';
import FormProduct from '@/components/Products/FormProduct';
import { arrActions } from '@/mixins';

const productsDb = new Products();

export default {
  components: { Toaster, Modal, FormProduct, draggable },
  mixins: [ arrActions ],

  data() {
    return {
      products: [],
      showModal: false,
      activeProduct: {},
      availableCategories: [],
      availableTags: [],
    };
  },

  computed: {
    ...mapGetters('app', ['baseurl']),

    productList: {
      get() {
        return this.products;
      },
      set(value) {
        this.products = []

        /* eslint-disable-next-line */
        for (const [index, product] of value.entries()) {
          product.sortOrder = (index + 1);

          this.products.push(product);
        }
      }
    },
  },

  created() {
    this.getProducts();
  },

  methods: {
    ...mapActions('toaster', ['setToaster']),
  
    async getProducts() {
      const products = await productsDb.readAllSorted({key: 'sortOrder', direction: 'asc'});

      this.updateFilters(products);

      this.products = products;
    },

    edit(index) {
      this.activeProduct = Object.assign({}, this.products[index]);
      setTimeout(() => {
        this.showModal = true;
      }, 100);
    },

    async remove(index) {
      await productsDb.delete(this.products[index].id);
      this.products.splice(index, 1);

       this.setToaster({
          message: `Le produit a été supprimé`,
          type: 'message',
          time: Date.now(),
        });
    },

    close() {
      this.activeProduct = {};
      this.showModal = false;
    },

    async createProduct(product) {
      try {
        const response = await productsDb.create(product);

        this.products.push(response);

        this.setToaster({
          message: `Le produit a été créé`,
          type: 'message',
          time: Date.now(),
        });
        this.showModal = false;
  
      } catch (error) {
        this.setToaster({
          message: `Une erreur est survenue`,
          type: 'error',
          time: Date.now(),
        });
      }
    },

    async saveProduct(product) {
      try {
        const index = this.findIndexInData(this.products, 'id', product.id);
        await productsDb.update(product);

        this.products[index] = Object.assign({}, product);

        this.updateFilters(this.products);

        this.setToaster({
          message: `Le produit a été mis à jour`,
          type: 'message',
          time: Date.now(),
        });
        this.showModal = false;
      } catch (error) {
        this.setToaster({
          message: `Une erreur est survenue`,
          type: 'error',
          time: Date.now(),
        });
      }
    },

    error(error) {
      this.setToaster({
        message: `Une erreur est survenue : ${error}`,
        type: 'error',
        time: Date.now(),
      });
    },

    async saveFile({id, path}) {
      try {
        const index = this.findIndexInData(this.products, 'id', id);
        const product = Object.assign(this.products[index], {picture: path.replace(/public\//g,'')});

        await productsDb.update(product);

        this.products[index] = Object.assign({}, product);

        this.setToaster({
          message: `La photo a été ajoutée`,
          type: 'message',
          time: Date.now(),
        });
        this.showModal = false;
  
      } catch (error) {
        this.setToaster({
          message: `Une erreur est survenue`,
          type: 'error',
          time: Date.now(),
        });
      }
    },

    addProduct() {
      this.activeProduct = Object.assign({}, {
        active: false,
        categories: [],
        tags: [],
        name: '',
        origin: [{ 
          country: '',
          region: '',
          places: []
        }],
        picture: '',
        prices:[{
          price:'',
          unitPrice: '',
        }],
        ingredients: [],
        sortOrder:1000,
      });
      setTimeout(() => {
        this.showModal = true;
      }, 100);
    },

    saveOrder() {
      try {
        this.products.map(async (product) => {
          await productsDb.update(product);
        });

        this.setToaster({
          message: `L'ordre a été enregistré`,
          type: 'message',
          time: Date.now(),
        });
      } catch (error) {
        this.setToaster({
          message: `Une erreur est survenue : ${error}`,
          type: 'error',
          time: Date.now(),
        });
      }
    },

    updateFilters(products) {
      products.map(product => {
        let categories = [];
        let tags = [];

        product.categories.map(c => {
          if (this.availableCategories.indexOf(c) === -1) {
            categories.push(c);
          }
        });

        if (product.tags) {
          product.tags.map(tag => {
            if (this.availableTags.indexOf(tag) === -1) {
              tags.push(tag);
            }
          });
        }

        if (categories && categories.length) this.availableCategories.push(...categories);
        if (tags && tags.length) this.availableTags.push(...tags);
      });
    },

    async toggleActive(index) {
      try {
        this.products[index].active = !this.products[index].active;
  
        await productsDb.update(this.products[index]);

        this.setToaster({
          message: `Modification enregistrée`,
          type: 'message',
          time: Date.now(),
        });
      } catch (error) {
        this.setToaster({
          message: `Une erreur est survenue : ${error}`,
          type: 'error',
          time: Date.now(),
        });
      }
    },
  }
};
</script>